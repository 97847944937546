import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportPayload } from '../../../models/monitoring';
import { closeDialog } from '../../../store/dialog/dialog.action';
import { setIsError } from '../../../store/error/error.action';
import { addReportStart } from '../../../store/report/report.action';
import DialogAddReportAttachmentsStep from './steps/dialog-add-report-attachments-step/dialog-add-report-attachments-step.component';
import DialogAddReportImagesStep from './steps/dialog-add-report-images-step/dialog-add-report-images-step.component';
import DialogAddReportInfoStep from './steps/dialog-add-report-info-step/dialog-add-report-info-step.component';
import DialogAddReportServiceStep from './steps/dialog-add-report-service-step/dialog-add-report-service-step.component';
import DialogAddReportSeverityStep from './steps/dialog-add-report-severity-step/dialog-add-report-severity-step.component';

const DialogAddReport = ({
    inputData,
    onSaved,
}: {
    inputData: any;
    onSaved: () => void;
}) => {
    const [step, setStep] = useState<number>(0);
    const [reportToAdd, setReportToAdd] = useState<Partial<ReportPayload>>({});
    const dispatch = useDispatch();

    const handleNextStep = (
        properties: Partial<ReportPayload>,
        save = false
    ) => {
        setReportToAdd((prevState) => {
            return {
                ...prevState,
                ...properties,
            };
        });
        if (!save) {
            setStep(step + 1);
        }
        if (save) {
            handleSave({
                ...reportToAdd,
                ...properties,
            });
        }
    };

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleSave = async (report: Partial<ReportPayload>) => {
        if (!report) {
            dispatch(setIsError(true, 'Dati insufficienti per il salvataggio'));
            return;
        }
        dispatch(
            addReportStart(report, () => {
                onSaved();
                dispatch(closeDialog());
            })
        );
    };

    return (
        <Fragment>
            {step === 0 && (
                <DialogAddReportServiceStep
                    elementServices={inputData.elementServices}
                    elementServiceSelected={reportToAdd.element_service}
                    onNext={handleNextStep}
                />
            )}
            {step === 1 && (
                <DialogAddReportInfoStep
                    name={reportToAdd.name}
                    description={reportToAdd.description}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 2 && (
                <DialogAddReportImagesStep
                    images={reportToAdd.images}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 3 && (
                <DialogAddReportAttachmentsStep
                    attachments={reportToAdd.attachments}
                    onPrevious={handlePreviousStep}
                    onNext={handleNextStep}
                />
            )}
            {step === 4 && (
                <DialogAddReportSeverityStep
                    onPrevious={handlePreviousStep}
                    onNext={(properties) => handleNextStep(properties, true)}
                />
            )}
        </Fragment>
    );
};

export default DialogAddReport;

import FileResizer from "react-image-file-resizer";
import { MAX_SIZE_ATTACHMENT_BYTES } from '../../constants/files.constants';

export function checkExtensionImage(nameFile: string) {
    const typesFilesOk: string[] = ['jpg', 'jpeg', 'png', 'svg'];
    const tmp = nameFile.toLowerCase().split('.');
    if (typesFilesOk.includes(tmp[tmp.length - 1])) {
        return true;
    }
    return false;
};

export function checkExtensionFile(nameFile: string) {
    const typesFilesOk: string[] = ['pdf', 'docx', 'txt'];
    const tmp = nameFile.toLowerCase().split('.');
    if (typesFilesOk.includes(tmp[tmp.length - 1])) {
        return true;
    }
    return false;
};


export function checkFileSize(file: File) {
    return file.size <= MAX_SIZE_ATTACHMENT_BYTES;
};

export function resizeImage(file: File): Promise<File> {
    return new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            1024,
            1024,
            "JPEG",
            100,
            0,
            (file) => {
                const oldFile = file as File;
                const fileNameWithoutExtension = oldFile.name.split('.').slice(0, -1).join('.');
                const fileNameWithExtension = `${fileNameWithoutExtension}.jpeg`;
                const newFile = new File([oldFile], fileNameWithExtension, { type: "image/jpeg" });
                resolve(newFile as File);
            },
            "file"
        );
    });
}